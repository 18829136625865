import React, { useEffect } from "react";
import ue from "../assets/sponsor/unione-europea-fondo-sociale.jpg";
import rLiguria from "../assets/sponsor/regione-liguria.jpg";
import cnr from "../assets/sponsor/cnr.jpg";
import cGenova from "../assets/sponsor/comune-genova.jpg";
import uniGenova from "../assets/sponsor/uni-degli-studi-di-genova.png";
import asg from "../assets/sponsor/asg.jpg";
import cCommGenova from "../assets/sponsor/logo-camera-commercio-genova.jpg";
import cSanPaolo from "../assets/sponsor/compagnia-sanpaolo.jpg";
import lERG from "../assets/sponsor/logo-erg.jpg";
import useTransitionAccordion from "../utils/use-transition";
import { Link } from "react-router-dom";

const sponsor = [
  {
    role: "Partner Istituzionali",
    title: "Unione Europea fondo sociale",
    link: "http://ec.europa.eu/esf/home.jsp?langId=it",
    img: ue,
  },
  {
    title: "Regione Liguria",
    link: "http://www.regione.liguria.it",
    img: rLiguria,
  },
  {
    title: "Comune Genova",
    link: "http://www.comune.genova.it",
    img: cGenova,
  },
  {
    title: "Università degli studi di Genova",
    link: "https://www.unige.it",
    img: uniGenova,
  },
  {
    title: "CNR",
    link: "http://www.cnr.it",
    img: cnr,
  },
  {
    title: "Camera di Commercio di Genova",
    link: "http://www.ge.camcom.it/IT/HomePage",
    img: cCommGenova,
  },
  {
    role: "Principale Sostenitore",
    title: "Compagnia di San Paolo",
    link: "https://www.compagniadisanpaolo.it/",
    img: cSanPaolo,
  },
  {
    role: "Partner",
    title: "ASG",
    link: "https://www.asgsuperconductors.com/",
    img: asg,
  },
  {
    title: "ERG",
    link: "https://www.erg.eu/it/home",
    img: lERG,
  },
];

const BannerSponsor = () => {
  const [status, toggleOpen] = useTransitionAccordion(
    550,
    localStorage.getItem("fds-sponsor-open") || "open"
  );
  useEffect(() => {
    localStorage.setItem("fds-sponsor-open", status);
  }, [status]);

  useEffect(() => {
    if (status === "open") {
      document.getElementById("app-container").classList.add("sponsor-open");
    } else {
      document.getElementById("app-container").classList.remove("sponsor-open");
    }
  }, [status]);

  return (
    <div className="banner-sponsor hidden-print">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="banner-sponsor__all">
              <Link
                title="Sponsor 2024"
                to="/diventa-sponsor/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Diventa sponsor
              </Link>
              &#160;|&#160;
              <Link
                title="Sponsor 2024"
                to="/sponsor"
                target="_blank"
                rel="noopener noreferrer"
              >
                Sponsor 2024
              </Link>
            </div>
            <button
              className="banner-sponsor__btn banner-sponsor__btn--arrow"
              title="Sponsor"
              onClick={toggleOpen}
            >
              Sponsor
            </button>
            <div
              className={`banner-sponsor__wrapper ${
                status === "open" ? " banner-sponsor__wrapper--open" : ""
              }${
                status === "transition"
                  ? " banner-sponsor__wrapper--transition-closed"
                  : ""
              }`}
            >
              {sponsor.map((item, i) => (
                <div
                  className={`banner-sponsor__item${
                    i === 0 ? " banner-sponsor__item--first" : ""
                  }`}
                  key={i}
                >
                  {item.role ? (
                    <div className="banner-sponsor__role">{item.role}</div>
                  ) : null}
                  <a
                    className="banner-sponsor__link"
                    title={item.title}
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img alt={item.title} src={item.img} />
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerSponsor;

import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { NOFESTIVAL_MODE, ONLYEDITION_MODE } from "../utils/constants";
import UserMenu from "./user-menu";
import { useNavigazione } from "../context/navigazione";
import MenuItem from "./menu-item";

const Header = ({ isHome, data }) => {
  const { topMenu, mode, imgEdizione, testata, testataMobile } =
    useNavigazione();
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    if (menuOpen) {
      document.body.classList.add("pushmenu-push-toright");
    }
    return () => document.body.classList.remove("pushmenu-push-toright");
  }, [menuOpen]);

  return (
    <header
      role="banner"
      className={classNames(
        "header",
        mode !== NOFESTIVAL_MODE && isHome && "header--festival"
      )}
    >
      <div className="header__utils pt-1">
        <div className="container-md">
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-between mb-2">
                <a
                  className="btn btn--arrow align-self-center"
                  title="Festival Scienza Online"
                  href="https://www.festivalscienza.online/"
                >
                  Eventi online
                </a>
                <div>
                  {mode !== NOFESTIVAL_MODE && mode !== ONLYEDITION_MODE && (
                    <UserMenu />
                  )}
                  <a
                    className="btn"
                    title="Eng"
                    href="http://www.festivalscienza.eu"
                  >
                    Eng
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={classNames(
          "header__top",
          mode !== NOFESTIVAL_MODE ? " mb-3 mb-md-0 pb-5 pb-md-4" : "pb-3",
          mode !== NOFESTIVAL_MODE &&
            !isHome &&
            "mb-3 mb-md-0 header__top--festival"
        )}
        style={{
          backgroundImage: `url(${
            mode !== NOFESTIVAL_MODE && !isHome
              ? imgEdizione + "&w=1920"
              : "none"
          })`,
        }}
      >
        <div className="container-md">
          <div className="row">
            <div className="d-print-none d-block d-md-none header__mobile mb-2">
              <div
                className={classNames(
                  "header__hamburger",
                  menuOpen && "header__hamburger--open"
                )}
                onClick={() => setMenuOpen(!menuOpen)}
              >
                <span>&nbsp;</span>
                <span>&nbsp;</span>
                <span>&nbsp;</span>
                <span>&nbsp;</span>
              </div>
              <div className="clear">&nbsp;</div>
            </div>
            <div className="col-12">
              <div
                itemScope="itemScope"
                itemType="https://schema.org/Organization"
                className="d-print-none"
              >
                <Link itemProp="url" title="Festival della Scienza" to="/">
                  <picture>
                    <source
                      media="(max-width: 768px)"
                      srcSet={`${testataMobile ?? testata}&w=800`}
                    />
                    <source
                      media="(min-width: 769px)"
                      srcSet={`${testata}&w=1920`}
                    />
                    <img
                      src={`${testata}&w=1920`}
                      alt="Festival della Scienza"
                    />
                  </picture>

                  {/* <img
                    itemProp="image"
                    alt="Festival della Scienza"
                    src={testata}
                  /> */}
                  {/*  <img
                    itemProp="image"
                    className="d-block d-md-none"
                    alt="Festival della Scienza"
                    src={logoMobile}
                  /> */}
                  <span itemProp="name" className="d-none">
                    Festival della Scienza
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={classNames(
          "header__bottom pushmenu pushmenu-left",
          menuOpen && "pushmenu-open"
        )}
      >
        <div className="container-lg">
          <div className="row">
            <div className="col-12 gx-0 gx-md-3">
              <nav
                role="navigation"
                itemScope="itemscope"
                itemType="https://schema.org/SiteNavigationElement"
                className="navigation hidden-print"
              >
                <ul
                  aria-orientation="horizontal"
                  role="menu"
                  className="navigation__list d-flex flex-column flex-md-row  flex-wrap"
                >
                  {topMenu.map((item, index) => (
                    <MenuItem
                      selected={data?.some((p) => p.label === item.name)}
                      item={item}
                      index={index}
                      key={index}
                    />
                  ))}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;

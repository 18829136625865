import { filterPageByMode } from "./utility";

const CONTENTFUL_SPACE_ID = import.meta.env.VITE_CONTENTFUL_SPACE_ID;
const CONTENTFUL_TOKEN = import.meta.env.VITE_CONTENTFUL_TOKEN;
const CONTENTFUL_ENVIRONMENT =
  import.meta.env.VITE_CONTENTFUL_ENVIRONMENT || "master";
const OVERWRITE_MODE = import.meta.env.VITE_OVERWRITE_MODE;
const ASSETS_URL_REGEX = /^https:\/\/(images|assets)\.ctfassets\.net\//;

const parseAndReplaceAssetUrls = (jsonString) =>
  JSON.parse(jsonString, (key, value) =>
    key === "url" && ASSETS_URL_REGEX.test(value)
      ? value.replace(ASSETS_URL_REGEX, "/")
      : value
  );

const fetchGraphql = async (query, variables = {}) => {
  try {
    const response = await fetch(
      `https://graphql.contentful.com/content/v1/spaces/${CONTENTFUL_SPACE_ID}/environments/${CONTENTFUL_ENVIRONMENT}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${CONTENTFUL_TOKEN}`,
        },
        body: JSON.stringify({ query, variables }),
      }
    );
    if (!response.ok) {
      throw new Error(`Fetch error ${response.status} ${response.statusText}`);
    }
    const jsonString = await response.text();
    const { data, errors } = parseAndReplaceAssetUrls(jsonString);
    const criticalErrors =
      errors?.filter(
        (error) => error.extensions?.contentful?.code !== "UNRESOLVABLE_LINK"
      ) || [];
    if (criticalErrors?.length) {
      throw new Error(criticalErrors[0].message);
    } else if (errors?.length) {
      console.warn(errors[0].message);
    }
    return data;
  } catch (err) {
    console.log("Graphql error: ", err);
    throw err;
  }
};

const fetchCms = async (path) => {
  const response = await fetch(`/cms/${path}`);
  if (!response.ok) {
    throw new Error(`Fetch error ${response.status} ${response.statusText}`);
  }
  const data = await response.json();
  return data;
};

const TEMPLATES = {
  Home: "home",
  Programma: "programma",
  "Edizioni precedenti": "archivio",
  "Programma scuole": "programmaScuole",
  Protagonisti: "protagonisti",
  "Area clienti": "areaClienti",
  "Biglietti e Prenotazioni": "bigliettiPrenotazioni",
  "Iscrizione newsletter": "newsletter",
  Sponsor: "sponsor",
  "Scrivi al Festival": "scriviFestival",
  "Early bird": "earlyBird",
  "Acquista biglietti": "biglietti",
  "Inserisci Premium": "premium",
  "Completa acquisto": "carrello",
  "Elenco acquisti": "ordini",
  Extra: "programmaExtra",
};

const pageFromData = (data, allPages) => {
  const page = {
    name: data.titolo,
    link: `/${data.slug !== "/" ? data.slug : ""}`,
    template: data.layout ? TEMPLATES[data.layout] || "generic" : null,
    label: data?.label,
  };
  const parent = allPages.find((p) => {
    return p.pagineFiglieCollection.items.find((f) => f.slug === data.slug);
  });

  if (parent) {
    page.parent = {
      name: parent.titolo,
      link: `/${parent.slug}`,
    };
  }
  /*  if (data.linkedFrom?.paginaCollection?.items?.length) {
    const parentPage = data.linkedFrom.paginaCollection.items[0];
    page.parent = {
      name: parentPage.titolo,
      link: `/${parentPage.slug}`,
    }; 
  }*/
  return {
    ...page,
    pages: data.pagineFiglieCollection?.items?.length
      ? data.pagineFiglieCollection?.items.map((sub) => ({
          name: sub.titolo,
          link: `/${sub.slug}`,
          template: sub.layout ? TEMPLATES[sub.layout] || "generic" : null,
          parent: page,
        }))
      : null,
  };
};

export const pageFromFields = (fields) => {
  const page = {
    name: fields.titolo,
    link: `/${fields.slug !== "/" ? fields.slug : ""}`,
    template: TEMPLATES[fields.layout] || "generic",
  };
  return {
    ...page,
    pages: fields.pagineFiglie?.map((sub) => ({
      name: sub.fields.titolo,
      link: `/${sub.fields.slug}`,
      template: TEMPLATES[fields.layout] || "generic",
      parent: page,
    })),
  };
};

export const getNavigation = async (isAdmin) => {
  // console.log("isAdmin", isAdmin);
  // const { navigazioneCollection, paginaCollection } = await fetchGraphql(`{
  //   navigazioneCollection(limit: 1) {
  //     items {
  //       sponsor
  //       modalitFestival
  //       topMenuCollection {
  //         items {
  //           titolo
  //           slug
  //           layout
  //           pagineFiglieCollection {
  //             items {
  //               __typename
  //             	...on Pagina {
  //                 titolo
  //               	slug
  //               	layout
  //               }
  //               ...on PaginaLink {
  //                 titolo
  //                 slug: link
  //               }
  //             }
  //           }
  //         }
  //       }
  //       footerCollection {
  //         items {
  //           titolo
  //           testo {
  //             json
  //           }
  //           link {
  //             titolo
  //             slug
  //           }
  //         }
  //       }
  //     }
  //   }
  //   paginaCollection {
  //     items {
  //       titolo
  //       slug
  //       layout
  //       linkedFrom {
  //         paginaCollection(limit: 1) {
  //           items {
  //             titolo
  //             slug
  //           }
  //         }
  //       }
  //     }
  //   }
  // }`);
  const { navigazioneCollection, paginaCollection } = await fetchCms(
    "navigazione.json"
  );
  const allPages = paginaCollection.items;
  const mode =
    OVERWRITE_MODE || navigazioneCollection?.items?.[0]?.modalitFestival;
  const earlyBirdPremium = navigazioneCollection?.items?.[0]?.earlyBirdPremium;
  const menuPages =
    filterPageByMode(
      navigazioneCollection?.items?.[0]?.topMenuCollection?.items,
      mode
    ) || [];
  const linkVideo = navigazioneCollection?.items?.[0]?.linkVideo;
  const imgEdizione =
    navigazioneCollection?.items?.[0]?.immagineTestataEdizione?.url.replace(
      ASSETS_URL_REGEX,
      "/"
    );
  const testata =
    navigazioneCollection?.items?.[0]?.testataEdizione?.url.replace(
      ASSETS_URL_REGEX,
      "/"
    );
  const testataMobile =
    navigazioneCollection?.items?.[0]?.testataEdizioneMobile?.url.replace(
      ASSETS_URL_REGEX,
      "/"
    );
  const programma = navigazioneCollection?.items?.[0]?.programma?.url.replace(
    ASSETS_URL_REGEX,
    "/"
  );
  const bannerOrientamento =
    navigazioneCollection?.items?.[0]?.bannerOrientamento?.url.replace(
      ASSETS_URL_REGEX,
      "/"
    );
  const altrePagine = isAdmin
    ? paginaCollection?.items
    : filterPageByMode(paginaCollection?.items, mode) || [];
  const footer =
    navigazioneCollection?.items?.[0]?.footerCollection?.items || [];

  const sponsor = navigazioneCollection?.items?.[0]?.sponsor;
  const mainColor = navigazioneCollection?.items?.[0]?.mainColor;

  const pages = altrePagine.map((item) => pageFromData(item, allPages));

  const topMenu = [
    {
      name: "Home",
      link: "/",
      template: "home",
    },
  ].concat(menuPages.map((item) => pageFromData(item, allPages)));

  return {
    pages,
    topMenu,
    footer,
    sponsor,
    mode,
    earlyBirdPremium,
    linkVideo,
    testata,
    testataMobile,
    imgEdizione,
    programma,
    bannerOrientamento,
    mainColor,
  };
};

export const getNews = async () => {
  const response = await fetch("/mock/news.json");
  const data = response.json();
  return data;
};

export const getEdizioni = async () => {
  const { edizionePrecedenteCollection } = await fetchGraphql(`{
    edizionePrecedenteCollection(order: anno_DESC) {
      items {
        titolo
        testo
        visite
        eventi
        relatori
        location
        link
        immagine {
          url(transform: {format:WEBP})
          title
        }
      }
    }
  }`);
  return edizionePrecedenteCollection?.items;
};

export const getHome = async () => {
  const { paginaCollection } = await fetchGraphql(`{
    paginaCollection(limit: 1, where: {layout: "Home"}) {
      items {
        titolo
        titoloInPagina
        metaDescription
        articoliCollection(limit: 5) {
          items {
            __typename
            ... on FasciaArticoli {
              layout
              sfondoGrigio
              titoloSezione
              articoliCollection(limit: 6) {
                items {
                  __typename
                  ... on Pagina {
                    titolo
                    slug
                    layout
                    etichetta
                    titoloInBoxLinea1
                    titoloInBoxLinea2
                    titoloInPagina
                    sommario {
                      json
                    }
                    immagineCard {
                      url(transform: {format:WEBP})
                    }
                  }
                  ... on ArticoloPlaceholder {
                    titolo
                    titoloInBoxLinea1
                    titoloInBoxLinea2
                    etichetta
                    idEvento
                    linkEsterno
                    immagineCard: immagine {
                      url(transform: {format:WEBP})
                    }
                    sommario {
                      json
                    }
                  }
                  ... on Articolo {
                    sys {
                      id
                    }
                    immagineCard: immagine {
                      url(transform: {format:WEBP})
                    }
                    sommario {
                      json
                    }
                    titoloInBoxLinea1
                    titoloInBoxLinea2
                    titolo
                    slug
                    etichetta
                  }
                }
              }
            }
          }
        }
      }
    }
  }`);
  return paginaCollection?.items?.[0] || [null];
};

export const getPage = async (slug) => {
  const { paginaCollection } = await fetchGraphql(
    `query Pagina($slug: String!) {
      paginaCollection(limit: 1, where: {slug: $slug}) {
        items {
          titolo
          titoloInPagina
          metaDescription
          slug
          layout
          rientroInPagina
          label
          testo {
            json
            links {
              assets {
                hyperlink {
                  title
                  url
                  sys {
                    id
                  }
                }
              }
              entries {
                block {
                  __typename
                  sys {
                    id
                  }
                  ... on HtmlEmbed {
                    html
                  }
                }
                hyperlink {
                  sys {
                    id
                  }
                  __typename
                  ... on Pagina {
                    slug
                  }
                  ... on Articolo {
                    slug
                  }
                  ... on ArticoloPlaceholder {
                    linkEsterno
                    idEvento
                  }
                }
              }
            }
          }
          articoliCollection {
            items {
              __typename
              ... on FasciaArticoli {
                layout
                sfondoGrigio
                rientro
                sys {
                  id
                }
              }
              ... on FasciaDidattica {
                sys {
                  id
                }
              }
              ... on FasciaPersone {
                sys {
                  id
                }
              }
              ... on FasciaSponsor {
                sys {
                  id
                }
                tipologiaSponsor
                sponsorCollection(limit: 15) {
                  items {
                    sys {
                      id
                    }
                    url(transform: {format:WEBP})
                    title
                    description
                  }
                }
              }
            }
          }
        }
      }
    }`,
    {
      slug,
    }
  );
  return paginaCollection?.items?.[0] || null;
};

export const getArticle = async (slug) => {
  const { articoloCollection } = await fetchGraphql(
    `query Articolo($slug: String!) {
      articoloCollection(limit: 1, where: {slug: $slug}) {
        items {
          titolo
          rientroInPagina
          testo {
            json
            links {
              assets {
                hyperlink {
                  title
                  url
                  sys {
                    id
                  }
                }
              }
              entries {
                block {
                  __typename
                  sys {
                    id
                  }
                  ... on HtmlEmbed {
                    html
                  }
                }
                hyperlink {
                  sys {
                    id
                  }
                  __typename
                  ... on Pagina {
                    slug
                  }
                  ... on Articolo {
                    slug
                  }
                  ... on ArticoloPlaceholder {
                    linkEsterno
                    idEvento
                  }
                }
              }
            }
          }
        }
      }
    }`,
    {
      slug,
    }
  );
  return articoloCollection?.items?.[0] || null;
};

export const getSponsor = async () => {
  const { paginaCollection } = await fetchGraphql(
    `{
      paginaCollection(limit: 1, where: {layout: "Sponsor"}) {
        items {
          titolo
          articoliCollection {
            items {
              __typename
              ... on FasciaSponsor {
                sys {
                  id
                }
                tipologiaSponsor
                sponsorCollection {
                  items {
                    sys {
                      id
                    }
                    url
                    description
                  }
                }
              }
            }
          }
        }
      }
    }`
  );
  return paginaCollection?.items?.[0] || null;
};

export const getFasciaDidattica = async (id) => {
  const { fasciaDidattica } = await fetchGraphql(
    `query FasciaDidattica($id: String!){
      fasciaDidattica(id: $id) {
        titolo
        schedeCollection {
          items {
            titolo
            descrizione
            immagine {
              title
              url(transform: {format:WEBP})
            }
            difficolta
            eta
            durata
            disciplina
            link {
              ... on Articolo {
                titolo
                slug
              }
              ... on Pagina {
                titolo
                slug
              }
            }
          }
        }
      }
    }`,
    {
      id,
    }
  );

  return fasciaDidattica || null;
};

export const getFasciaPersone = async (id) => {
  const { fasciaPersone } = await fetchGraphql(
    `query FasciaPersone($id: String!){
      fasciaPersone(id: $id) {
        titoloSezione
        sfondoGrigio
        layout 
        personeCollection {
          items {
            nome
            cognome
            ruolo
            bio
            foto {
              title
              url(transform: {format:WEBP})
            }
            link
          }
        }
      }
    }`,
    {
      id,
    }
  );

  return fasciaPersone || null;
};

export const getFasciaArticoli = async (id) => {
  const { fasciaArticoli } = await fetchGraphql(
    `query FasciaArticoli($id: String!){
      fasciaArticoli(id: $id) {
        titoloSezione
        layout
        sfondoGrigio
        rientro
        articoliCollection(limit: 10) {
          items {
            __typename
            ... on Pagina {
              titolo
              slug
              titoloInBoxLinea1
              titoloInBoxLinea2
              titoloInPagina
              etichetta
              sommario {
                json
              }
              immagineCard {
                url(transform: {format:WEBP})
              }
            }
            ... on ArticoloPlaceholder {
              titolo
              titoloInBoxLinea1
              titoloInBoxLinea2
              etichetta
              idEvento
              linkEsterno
              immagineCard: immagine {
                url(transform: {format:WEBP})
              }
              sommario {
                json
              }
            }
            ... on Articolo {
              sys {
                id
              }
              sommario {
                json
              }
              titoloInBoxLinea1
              titoloInBoxLinea2
              titolo
              immagineCard: immagine {
                url(transform: {format:WEBP})
              }
              slug
              testo {
                json
                links {
                  assets {
                    hyperlink {
                      title
                      url
                      sys {
                        id
                      }
                    }
                  }
                  entries {
                    block {
                      __typename
                      sys {
                        id
                      }
                      ... on HtmlEmbed {
                        html
                      }
                    }
                    hyperlink {
                      sys {
                        id
                      }
                      __typename
                      ... on Pagina {
                        slug
                      }
                      ... on Articolo {
                        slug
                      }
                      ... on ArticoloPlaceholder {
                        linkEsterno
                        idEvento
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }`,
    {
      id,
    }
  );

  return fasciaArticoli || null;
};

import React, { useState, useContext, useMemo } from "react";
import Breadcrumbs from "../components/breadcrumbs";
import Header from "../components/header";
import Steps from "../components/steps";
import Modal from "react-overlays/Modal";
import Icon from "../components/icons";
import {
  getDayDesc,
  isValidDay,
  getTicketDesc,
  getPlanetACode,
  getPlanetBCode,
  getPlanetRiduzione,
  getPlanetRiduzionePremium,
} from "../utils/utility";
import {
  useCart,
  useRemoveTicket,
  useRemoveBooking,
} from "../hooks/useQueryCart";
import UserContext from "../context/user";
import Seo from "../components/seo";
import { Link } from "react-router-dom";
import { useNavigazione } from "../context/navigazione";
import { ECOMMERCE_MODE } from "../utils/constants";
import { useQueryClient } from "@tanstack/react-query";

const CARRELLO_ACTION = import.meta.env.VITE_CARRELLO_ACTION;

const Carrello = () => {
  const { userProfile, callApi, isAmico } = useContext(UserContext);
  const queryClient = useQueryClient();
  const [showModal, setShowModal] = useState(false);
  const [placement, setPlacement] = useState("");
  const { getPathByTemplate, mode } = useNavigazione();
  const bigliettiPrenotazioniLink = getPathByTemplate("bigliettiPrenotazioni");
  const [submitting, setSubmitting] = useState(false);
  const breadcrumbs = useMemo(() => {
    return [
      {
        label: "Home",
        link: "/",
      },
      {
        label: "Biglietti e prenotazioni",
        link: bigliettiPrenotazioniLink,
      },
      {
        label: "Completa l'acquisto",
      },
    ];
  }, [bigliettiPrenotazioniLink]);
  const ordiniLink = getPathByTemplate("ordini");

  const removeTicket = useRemoveTicket();
  const removeBooking = useRemoveBooking();
  const { data: cart, isLoading } = useCart();
  const onHide = () => {
    setShowModal(false);
    setPlacement(null);
  };
  const handleSubmit = async (e) => {
    console.log("submit form acquisto");
    e.preventDefault();
    setSubmitting(true);
    try {
      const data = await callApi("/api/ecommerce/checkout", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log(data);
      if (data?.message !== "Success") {
        setPlacement("Servizio attualmente non disponibile");
        setShowModal(true);
      } else {
        if (Number(cart.costo.costo_tot === 0)) {
          const params = {
            codice_ordine: cart.id_carrello,
            stato: "OK",
            codice_voucher: "1111" + Math.floor(Math.random() * 10000),
          };
          const response = await callApi("/api/ecommerce/conferma-acquisto", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(params),
          });
          console.log(response);
          if (response?.message !== "Success") {
            setPlacement("Acquisto completato con successo");
          } else {
            setPlacement("Si è verificato un errore");
          }
          setShowModal(true);
        } else {
          queryClient.setQueryData(["cart", userProfile?.username], undefined);
          e.target.submit();
        }
      }
    } catch (error) {
      if (error?.code === "J3") {
        setPlacement(
          "Riciesta eseguita dopo il termine temporale di validità delle prenotazioni associate"
        );
      } else {
        setPlacement("Servizio attualmente non disponibile");
      }
      setShowModal(true);
    }
  };

  const onRemoveTck = async () => {
    //e.preventDefault();
    try {
      removeTicket.mutate();
    } catch (err) {
      console.error("Error: ", err);
    }
  };

  const onRemoveBooking = async (idSlot) => {
    //e.preventDefault();
    console.log("id_slot", idSlot);
    try {
      removeBooking.mutate({ id_slot: idSlot });
    } catch (err) {
      console.error("Error: ", err);
    }
  };

  return (
    <>
      <Seo title="Completa l'acquisto" />
      <Header data={breadcrumbs} />
      <Breadcrumbs data={breadcrumbs} />
      <div role="main" className="main-wrapper  mt-3" id="main">
        <div className="container-lg">
          <div className="row">
            <div className="col-12">
              <h1>Completa l'acquisto</h1>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <Steps selected={2} />
            </div>
          </div>
          {isLoading && (
            <div className="row">
              <div className="col-12">
                <div className="text-center my-5">
                  <Icon name="spinner" className="icon--big icon--animated" />
                </div>
              </div>
            </div>
          )}
          {isAmico ? (
            <div className="cart bg-gray px-4 py-5 mb-4">
              <div className="text-center">
                Le prenotazioni amico non hanno bisogno di conferma e sono
                riepilogate nella pagina{" "}
                <Link to={ordiniLink}>i tuo ordini</Link>
              </div>
            </div>
          ) : (
            <>
              {cart && (
                <div className="cart bg-gray px-4 py-5 mb-4">
                  <form
                    onSubmit={handleSubmit}
                    name="acquista"
                    method="post"
                    className="cart__form"
                    action={CARRELLO_ACTION}
                  >
                    <input
                      type="hidden"
                      name="cliente"
                      value={`${userProfile.attributes.name} ${userProfile.attributes.family_name}`}
                    />
                    <input
                      type="hidden"
                      name="email"
                      value={userProfile.attributes.email}
                    />
                    <input
                      type="hidden"
                      name="totale_incassare"
                      value={Math.round(Number(cart.costo.costo_tot) * 100)}
                    />
                    <input
                      type="hidden"
                      name="codice_ordine"
                      value={cart.id_carrello}
                    />
                    <input
                      type="hidden"
                      name="totale_titoli"
                      value={Math.round(Number(cart.costo.costo_tck) * 100)}
                    />
                    <div className="row">
                      <div className="col-12">
                        <h2 className="h3 graphic">
                          <span className="graphic__label graphic__label--gray-light">
                            Biglietti
                          </span>
                          <span className="graphic__line">&nbsp;</span>
                        </h2>
                        <div className="row">
                          <div className="col-lg-10 offset-lg-2">
                            {cart?.tck ? (
                              <>
                                <div className="row d-none d-sm-flex">
                                  <div className="col-4">
                                    <strong>Tipologia</strong>
                                  </div>
                                  <div className="col-3">
                                    <strong>Periodo</strong>
                                  </div>
                                  <div className="col-2">
                                    <strong>Quantità</strong>
                                  </div>
                                </div>
                                <div className="cart__row mb-2 py-3">
                                  <div className="row">
                                    <div className="col-12 col-sm-4">
                                      {cart.tck === "abb" && (
                                        <>
                                          {cart?.partecipanti.map(
                                            (item, index) => (
                                              <React.Fragment
                                                key={`p1_${index}`}
                                              >
                                                <input
                                                  type="hidden"
                                                  name={`titolo[${index}][specie]`}
                                                  value="A"
                                                />
                                                <input
                                                  type="hidden"
                                                  name={`titolo[${index}][quantita]`}
                                                  value="1"
                                                />
                                                <input
                                                  type="hidden"
                                                  name={`titolo[${index}][abbonamento]`}
                                                  value={getPlanetACode(mode)}
                                                />
                                                <input
                                                  type="hidden"
                                                  name={`titolo[${index}][riduzione]`}
                                                  value={getPlanetRiduzione(
                                                    mode,
                                                    item.type
                                                  )}
                                                />
                                              </React.Fragment>
                                            )
                                          )}
                                          Abbonamento
                                        </>
                                      )}
                                      {cart.tck === "premium" && (
                                        <>
                                          <input
                                            type="hidden"
                                            name="titolo[0][specie]"
                                            value="A"
                                          />
                                          <input
                                            type="hidden"
                                            name={`titolo[0][quantita]`}
                                            value="1"
                                          />
                                          <input
                                            type="hidden"
                                            name="titolo[0][abbonamento]"
                                            value={getPlanetACode(mode)}
                                          />
                                          <input
                                            type="hidden"
                                            name="titolo[0][riduzione]"
                                            value={getPlanetRiduzionePremium(
                                              mode
                                            )}
                                          />
                                          Abbonamento premium
                                        </>
                                      )}
                                      {isValidDay(cart.tck) && (
                                        <>
                                          {cart?.partecipanti.map(
                                            (item, index) => (
                                              <React.Fragment
                                                key={`p2_${index}`}
                                              >
                                                <input
                                                  type="hidden"
                                                  name={`titolo[${index}][specie]`}
                                                  value="B"
                                                />
                                                <input
                                                  type="hidden"
                                                  name={`titolo[${index}][quantita]`}
                                                  value="1"
                                                />
                                                <input
                                                  type="hidden"
                                                  name={`titolo[${index}][evento]`}
                                                  value={getPlanetBCode(
                                                    cart.tck
                                                  )}
                                                />
                                                <input
                                                  type="hidden"
                                                  name={`titolo[${index}][riduzione]`}
                                                  value={getPlanetRiduzione(
                                                    mode,
                                                    item.type
                                                  )}
                                                />
                                              </React.Fragment>
                                            )
                                          )}
                                          Giornaliero
                                        </>
                                      )}
                                    </div>
                                    <div className="col-12 col-sm-3">
                                      {cart.tck === "abb" &&
                                        "tutti i giorni del Festival"}
                                      {cart.tck === "premium" &&
                                        "tutti i giorni del Festival"}
                                      {isValidDay(cart.tck) &&
                                        getTicketDesc(cart.tck)}
                                    </div>
                                    <div className="col-12 col-sm-2">
                                      Interi {cart.num}
                                      {cart.num_raga > 0 &&
                                        ` - Young/serale ${cart.num_raga}`}
                                    </div>
                                    <div className="col-12 col-sm-2">
                                      <button
                                        className="btn"
                                        onClick={onRemoveTck}
                                        type="button"
                                      >
                                        Rimuovi
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div className="cart__row mb-3 py-3">
                                  <div className="row mb-3">
                                    <div className="col-12">
                                      <Icon name="ticket" className="mr-2" /> I
                                      biglietti sono nominali. Per ogni
                                      biglietto è necessario indicare
                                      l'intestatario.
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-sm-4">
                                      <strong>Intestatario</strong>
                                    </div>
                                    <div className="col-sm-4 d-none d-sm-block">
                                      <strong>Nome</strong>
                                    </div>
                                    <div className="col-sm-4 d-none d-sm-block">
                                      <strong>Cognome</strong>
                                    </div>
                                  </div>
                                  {cart?.partecipanti.map((item, index) => (
                                    <div
                                      key={`p3_${index}`}
                                      className="row my-2"
                                    >
                                      <div className="col-sm-4">Nominativo</div>
                                      <div className="col-sm-4">
                                        <input
                                          placeholder="Nome"
                                          type="text"
                                          required={true}
                                          minLength={2}
                                          name={`titolo[${index}][nome]`}
                                        />
                                      </div>
                                      <div className="col-sm-4">
                                        <input
                                          placeholder="Cognome"
                                          type="text"
                                          required={true}
                                          minLength={2}
                                          name={`titolo[${index}][cognome]`}
                                        />
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </>
                            ) : (
                              <div className="row my-3">
                                <div className="col-12 col-md-10">
                                  Nessun giornaliero o abbonamento
                                </div>
                              </div>
                            )}
                            <div className="row">
                              <div className="col-12 col-md-10">
                                <strong>
                                  Totale Biglietti:{" "}
                                  {(cart?.costo?.costo_tck || 0).toLocaleString(
                                    "it"
                                  )}{" "}
                                  euro
                                </strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-4">
                      {mode === ECOMMERCE_MODE && (
                        <div className="col-12">
                          <h2 className="h3 graphic">
                            <span className="graphic__label graphic__label--gray-light">
                              Prenotazioni
                            </span>
                            <span className="graphic__line">&nbsp;</span>
                          </h2>
                          <div className="row">
                            <div className="col-md-10 offset-md-2">
                              {cart?.book.length > 0 ? (
                                <>
                                  <div className="row">
                                    <div className="col-4 col-sm-4">
                                      <strong>Evento</strong>
                                    </div>
                                    <div className="col-4 col-sm-3">
                                      <strong>Periodo</strong>
                                    </div>
                                    <div className="col-4 col-sm-2">
                                      <strong>Quantità</strong>
                                    </div>
                                  </div>
                                  <div className="cart__row mb-3 py-3">
                                    {cart.book.map((item) => (
                                      <div
                                        key={item.id_book}
                                        className="row mb-2"
                                      >
                                        <div className="col-4 col-sm-4">
                                          {item.titolo}
                                        </div>
                                        <div className="col-4 col-sm-3">
                                          {getDayDesc(item.data_book)} -{" "}
                                          {item.orario_book}
                                        </div>
                                        <div className="col-4 col-sm-2">
                                          {item.num_book}
                                        </div>
                                        <div className="col-12 col-sm-3">
                                          <button
                                            className="btn"
                                            onClick={(e) =>
                                              onRemoveBooking(item.id_slot)
                                            }
                                            type="button"
                                          >
                                            Rimuovi
                                          </button>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </>
                              ) : (
                                <div className="cart__row">
                                  <div className="row  my-3">
                                    <div className="col-sm-12">
                                      Nessuna prenotazione nel carrello
                                    </div>
                                  </div>
                                </div>
                              )}

                              <div className="row  my-2">
                                <div className="col-sm-12">
                                  <strong>
                                    Totale prenotazioni:{" "}
                                    {(
                                      cart?.costo?.costo_book || 0
                                    ).toLocaleString("it")}{" "}
                                    euro
                                  </strong>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="col-12">
                        {(cart?.book.length > 0 || cart?.tck) && (
                          <div className="row">
                            <div className="col-md-10 offset-md-2">
                              <h2 className="my-4">
                                Totale:{" "}
                                {(cart?.costo?.costo_tot || 0).toLocaleString(
                                  "it"
                                )}{" "}
                                euro
                              </h2>
                              <button
                                id="acquista"
                                className="btn btn--link"
                                type="submit"
                                disabled={submitting}
                              >
                                {submitting && (
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                )}{" "}
                                {cart?.costo?.costo_tot
                                  ? "Acquista"
                                  : "Conferma"}
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              )}
              <Modal
                show={showModal}
                onHide={onHide}
                placement={placement}
                className="modal__internal d-flex flex-column"
                aria-labelledby="modal-label"
              >
                <>
                  <button
                    aria-label="close"
                    className="modal__close"
                    onClick={onHide}
                  >
                    <Icon className="ms-2" name="xmark" />
                  </button>
                  <div className="m-auto text-center">
                    <h2>{placement}</h2>
                  </div>
                </>
              </Modal>
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default Carrello;
